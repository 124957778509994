import { Button, Dropdown, Navbar, NavbarLink } from "flowbite-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

//styled components

const NewNavLink = styled(NavbarLink)`
    color: slate-900;
    font-size: 1.10rem;
    &:hover {
    color: slate-800;
    background-color: transparent;
  }
`;
const NewNavbarLink = styled(Navbar.Link)`
    color: slate-900;
    font-size: 1.10rem;
    &:hover {
    color: slate-800;
    background-color: transparent;
  }
`;

/* const NewDropdown = styled(Dropdown)`
    color: slate-900;
    font-size: 1.10rem;
    &:hover {
    color: slate-800;
  }
`; */

const Header = () => {

  return (

    <Navbar fluid rounded className="p-6">
      <Navbar.Brand href="/">
        <img src="/INFOLEET.svg" className="mr-3 h-6 sm:h-9" alt="Infoleet Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">
      <Button className="rounded-md p-0 bg-indigo-700 hover:bg-indigo-800"><Link to="/contact">Contact Us</Link></Button> 
       {/* eslint-disable-next-line */}
        {/* <Dropdown
          arrowIcon={false}
          inline
          label={
            "Get Started"
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">Bonnie Green</span>
            <span className="block truncate text-sm font-medium">name@flowbite.com</span>
          </Dropdown.Header>
          <Dropdown.Item>Dashboard</Dropdown.Item>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Item>Earnings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>Sign out</Dropdown.Item>
        </Dropdown> */}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <NewNavLink>
        <Dropdown
          arrowIcon={true}
          inline
          label = {"Services"}
        >
            {/* eslint-disable-next-line */}
          {/* <Dropdown.Header>
            <span className="block text-sm">Our Services</span>
            <span className="block truncate text-sm font-medium">Currently we offers</span>
          </Dropdown.Header> */}
          <Dropdown.Item href="/services/web-application-security">Web Application Security</Dropdown.Item>
          <Dropdown.Item href="/services/mobile-application-security">Mobile Security</Dropdown.Item>
          <Dropdown.Item href="/services/cloud-security-hardening">Cloud Security</Dropdown.Item>
          <Dropdown.Item href="/services/red-team-assessment">Red Team Assessments</Dropdown.Item>
          <Dropdown.Item href="/services/threat-intelligence-as-service">Threat Intelligence</Dropdown.Item>
          <Dropdown.Item href="/services">See All Services</Dropdown.Item>
          {/* <Dropdown.Divider /> */}
        
        </Dropdown>
        </NewNavLink>
        <NewNavbarLink href="/aboutus">Company</NewNavbarLink>
        <NewNavbarLink href="/careers">Careers</NewNavbarLink>
        <NewNavbarLink href="/resources">Resources</NewNavbarLink>
      </Navbar.Collapse>
    </Navbar>

  );
}
export default Header;